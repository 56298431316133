export const StringWrapper = function () {
  const Class = Object.getPrototypeOf(this).constructor;
  const Constructor = Function.bind.apply(String, [null, ...arguments]);
  const instance = new Constructor();
  Object.setPrototypeOf(instance, Class.prototype);
  return instance;
};
StringWrapper.prototype = Object.create(String.prototype, {
  constructor: {
    value: StringWrapper,
    enumerable: false,
    writable: true,
    configurable: true
  }
});
Object.setPrototypeOf(StringWrapper, String);
export class LazyJsonString extends StringWrapper {
  deserializeJSON() {
    return JSON.parse(super.toString());
  }
  toJSON() {
    return super.toString();
  }
  static fromObject(object) {
    if (object instanceof LazyJsonString) {
      return object;
    } else if (object instanceof String || typeof object === "string") {
      return new LazyJsonString(object);
    }
    return new LazyJsonString(JSON.stringify(object));
  }
}