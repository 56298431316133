"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Sha256 = void 0;
var tslib_1 = require("tslib");
var constants_1 = require("./constants");
var RawSha256_1 = require("./RawSha256");
var util_1 = require("@aws-crypto/util");
var Sha256 = /** @class */function () {
  function Sha256(secret) {
    this.secret = secret;
    this.hash = new RawSha256_1.RawSha256();
    this.reset();
  }
  Sha256.prototype.update = function (toHash) {
    if ((0, util_1.isEmptyData)(toHash) || this.error) {
      return;
    }
    try {
      this.hash.update((0, util_1.convertToBuffer)(toHash));
    } catch (e) {
      this.error = e;
    }
  };
  /* This synchronous method keeps compatibility
   * with the v2 aws-sdk.
   */
  Sha256.prototype.digestSync = function () {
    if (this.error) {
      throw this.error;
    }
    if (this.outer) {
      if (!this.outer.finished) {
        this.outer.update(this.hash.digest());
      }
      return this.outer.digest();
    }
    return this.hash.digest();
  };
  /* The underlying digest method here is synchronous.
   * To keep the same interface with the other hash functions
   * the default is to expose this as an async method.
   * However, it can sometimes be useful to have a sync method.
   */
  Sha256.prototype.digest = function () {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
      return tslib_1.__generator(this, function (_a) {
        return [2 /*return*/, this.digestSync()];
      });
    });
  };
  Sha256.prototype.reset = function () {
    this.hash = new RawSha256_1.RawSha256();
    if (this.secret) {
      this.outer = new RawSha256_1.RawSha256();
      var inner = bufferFromSecret(this.secret);
      var outer = new Uint8Array(constants_1.BLOCK_SIZE);
      outer.set(inner);
      for (var i = 0; i < constants_1.BLOCK_SIZE; i++) {
        inner[i] ^= 0x36;
        outer[i] ^= 0x5c;
      }
      this.hash.update(inner);
      this.outer.update(outer);
      // overwrite the copied key in memory
      for (var i = 0; i < inner.byteLength; i++) {
        inner[i] = 0;
      }
    }
  };
  return Sha256;
}();
exports.Sha256 = Sha256;
function bufferFromSecret(secret) {
  var input = (0, util_1.convertToBuffer)(secret);
  if (input.byteLength > constants_1.BLOCK_SIZE) {
    var bufferHash = new RawSha256_1.RawSha256();
    bufferHash.update(input);
    input = bufferHash.digest();
  }
  var buffer = new Uint8Array(constants_1.BLOCK_SIZE);
  buffer.set(input);
  return buffer;
}
