import { HttpRequest } from "@smithy/protocol-http";
const CONTENT_LENGTH_HEADER = "content-length";
export function contentLengthMiddleware(bodyLengthChecker) {
  return next => async args => {
    const request = args.request;
    if (HttpRequest.isInstance(request)) {
      const {
        body,
        headers
      } = request;
      if (body && Object.keys(headers).map(str => str.toLowerCase()).indexOf(CONTENT_LENGTH_HEADER) === -1) {
        try {
          const length = bodyLengthChecker(body);
          request.headers = {
            ...request.headers,
            [CONTENT_LENGTH_HEADER]: String(length)
          };
        } catch (error) {}
      }
    }
    return next({
      ...args,
      request
    });
  };
}
export const contentLengthMiddlewareOptions = {
  step: "build",
  tags: ["SET_CONTENT_LENGTH", "CONTENT_LENGTH"],
  name: "contentLengthMiddleware",
  override: true
};
export const getContentLengthPlugin = options => ({
  applyToStack: clientStack => {
    clientStack.add(contentLengthMiddleware(options.bodyLengthChecker), contentLengthMiddlewareOptions);
  }
});