"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEmptyData = void 0;
function isEmptyData(data) {
  if (typeof data === "string") {
    return data.length === 0;
  }
  return data.byteLength === 0;
}
exports.isEmptyData = isEmptyData;
