import { FieldPosition } from "@smithy/types";
export class Field {
  constructor({
    name,
    kind = FieldPosition.HEADER,
    values = []
  }) {
    this.name = name;
    this.kind = kind;
    this.values = values;
  }
  add(value) {
    this.values.push(value);
  }
  set(values) {
    this.values = values;
  }
  remove(value) {
    this.values = this.values.filter(v => v !== value);
  }
  toString() {
    return this.values.map(v => v.includes(",") || v.includes(" ") ? `"${v}"` : v).join(", ");
  }
  get() {
    return this.values;
  }
}