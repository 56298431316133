import { getChecksumConfiguration, resolveChecksumRuntimeConfig } from "./checksum";
export const getDefaultClientConfiguration = runtimeConfig => {
  return {
    ...getChecksumConfiguration(runtimeConfig)
  };
};
export const resolveDefaultRuntimeConfig = config => {
  return {
    ...resolveChecksumRuntimeConfig(config)
  };
};