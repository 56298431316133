export const createAggregatedClient = (commands, Client) => {
  for (const command of Object.keys(commands)) {
    const CommandCtor = commands[command];
    const methodImpl = async function (args, optionsOrCb, cb) {
      const command = new CommandCtor(args);
      if (typeof optionsOrCb === "function") {
        this.send(command, optionsOrCb);
      } else if (typeof cb === "function") {
        if (typeof optionsOrCb !== "object") throw new Error(`Expected http options but got ${typeof optionsOrCb}`);
        this.send(command, optionsOrCb || {}, cb);
      } else {
        return this.send(command, optionsOrCb);
      }
    };
    const methodName = (command[0].toLowerCase() + command.slice(1)).replace(/Command$/, "");
    Client.prototype[methodName] = methodImpl;
  }
};