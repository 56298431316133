import { memoize } from "@smithy/property-provider";
import { SignatureV4 } from "@smithy/signature-v4";
import { normalizeProvider } from "@smithy/util-middleware";
const CREDENTIAL_EXPIRE_WINDOW = 300000;
export const resolveAwsAuthConfig = input => {
  const normalizedCreds = input.credentials ? normalizeCredentialProvider(input.credentials) : input.credentialDefaultProvider(Object.assign({}, input, {
    parentClientConfig: input
  }));
  const {
    signingEscapePath = true,
    systemClockOffset = input.systemClockOffset || 0,
    sha256
  } = input;
  let signer;
  if (input.signer) {
    signer = normalizeProvider(input.signer);
  } else if (input.regionInfoProvider) {
    signer = () => normalizeProvider(input.region)().then(async region => [(await input.regionInfoProvider(region, {
      useFipsEndpoint: await input.useFipsEndpoint(),
      useDualstackEndpoint: await input.useDualstackEndpoint()
    })) || {}, region]).then(([regionInfo, region]) => {
      const {
        signingRegion,
        signingService
      } = regionInfo;
      input.signingRegion = input.signingRegion || signingRegion || region;
      input.signingName = input.signingName || signingService || input.serviceId;
      const params = {
        ...input,
        credentials: normalizedCreds,
        region: input.signingRegion,
        service: input.signingName,
        sha256,
        uriEscapePath: signingEscapePath
      };
      const SignerCtor = input.signerConstructor || SignatureV4;
      return new SignerCtor(params);
    });
  } else {
    signer = async authScheme => {
      authScheme = Object.assign({}, {
        name: "sigv4",
        signingName: input.signingName || input.defaultSigningName,
        signingRegion: await normalizeProvider(input.region)(),
        properties: {}
      }, authScheme);
      const isSigv4a = authScheme?.name === "sigv4a";
      const signingRegion = authScheme.signingRegion;
      const signingService = authScheme.signingName;
      let regionForSigner;
      if (isSigv4a) {
        regionForSigner = input.signingRegion || signingRegion;
      } else {
        input.signingRegion = input.signingRegion || signingRegion;
        regionForSigner = input.signingRegion;
      }
      input.signingName = input.signingName || signingService || input.serviceId;
      const params = {
        ...input,
        credentials: normalizedCreds,
        region: regionForSigner,
        service: input.signingName,
        sha256,
        uriEscapePath: signingEscapePath
      };
      const SignerCtor = input.signerConstructor || SignatureV4;
      return new SignerCtor(params);
    };
  }
  return {
    ...input,
    systemClockOffset,
    signingEscapePath,
    credentials: normalizedCreds,
    signer
  };
};
export const resolveSigV4AuthConfig = input => {
  const normalizedCreds = input.credentials ? normalizeCredentialProvider(input.credentials) : input.credentialDefaultProvider(Object.assign({}, input, {
    parentClientConfig: input
  }));
  const {
    signingEscapePath = true,
    systemClockOffset = input.systemClockOffset || 0,
    sha256
  } = input;
  let signer;
  if (input.signer) {
    signer = normalizeProvider(input.signer);
  } else {
    signer = normalizeProvider(new SignatureV4({
      credentials: normalizedCreds,
      region: input.region,
      service: input.signingName,
      sha256,
      uriEscapePath: signingEscapePath
    }));
  }
  return {
    ...input,
    systemClockOffset,
    signingEscapePath,
    credentials: normalizedCreds,
    signer
  };
};
const normalizeCredentialProvider = credentials => {
  if (typeof credentials === "function") {
    return memoize(credentials, credentials => credentials.expiration !== undefined && credentials.expiration.getTime() - Date.now() < CREDENTIAL_EXPIRE_WINDOW, credentials => credentials.expiration !== undefined);
  }
  return normalizeProvider(credentials);
};