import { parseQueryString } from "@smithy/querystring-parser";
export const parseUrl = url => {
  if (typeof url === "string") {
    return parseUrl(new URL(url));
  }
  const {
    hostname,
    pathname,
    port,
    protocol,
    search
  } = url;
  let query;
  if (search) {
    query = parseQueryString(search);
  }
  return {
    hostname,
    port: port ? parseInt(port) : undefined,
    protocol,
    path: pathname,
    query
  };
};