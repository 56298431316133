import { getChecksum } from "./getChecksum";
import { getChecksumAlgorithmListForResponse } from "./getChecksumAlgorithmListForResponse";
import { getChecksumLocationName } from "./getChecksumLocationName";
import { selectChecksumAlgorithmFunction } from "./selectChecksumAlgorithmFunction";
export const validateChecksumFromResponse = async (response, {
  config,
  responseAlgorithms
}) => {
  const checksumAlgorithms = getChecksumAlgorithmListForResponse(responseAlgorithms);
  const {
    body: responseBody,
    headers: responseHeaders
  } = response;
  for (const algorithm of checksumAlgorithms) {
    const responseHeader = getChecksumLocationName(algorithm);
    const checksumFromResponse = responseHeaders[responseHeader];
    if (checksumFromResponse) {
      const checksumAlgorithmFn = selectChecksumAlgorithmFunction(algorithm, config);
      const {
        streamHasher,
        base64Encoder
      } = config;
      const checksum = await getChecksum(responseBody, {
        streamHasher,
        checksumAlgorithmFn,
        base64Encoder
      });
      if (checksum === checksumFromResponse) {
        break;
      }
      throw new Error(`Checksum mismatch: expected "${checksum}" but received "${checksumFromResponse}"` + ` in response header "${responseHeader}".`);
    }
  }
};