import { MAXIMUM_RETRY_DELAY } from "./constants";
export const createDefaultRetryToken = ({
  retryDelay,
  retryCount,
  retryCost
}) => {
  const getRetryCount = () => retryCount;
  const getRetryDelay = () => Math.min(MAXIMUM_RETRY_DELAY, retryDelay);
  const getRetryCost = () => retryCost;
  return {
    getRetryCount,
    getRetryDelay,
    getRetryCost
  };
};