import { serializerMiddlewareOption } from "@smithy/middleware-serde";
import { httpAuthSchemeMiddleware } from "./httpAuthSchemeMiddleware";
export const httpAuthSchemeMiddlewareOptions = {
  step: "serialize",
  tags: ["HTTP_AUTH_SCHEME"],
  name: "httpAuthSchemeMiddleware",
  override: true,
  relation: "before",
  toMiddleware: serializerMiddlewareOption.name
};
export const getHttpAuthSchemePlugin = (config, {
  httpAuthSchemeParametersProvider,
  identityProviderConfigProvider
}) => ({
  applyToStack: clientStack => {
    clientStack.addRelativeTo(httpAuthSchemeMiddleware(config, {
      httpAuthSchemeParametersProvider,
      identityProviderConfigProvider
    }), httpAuthSchemeMiddlewareOptions);
  }
});