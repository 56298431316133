import { HttpRequest } from "@smithy/protocol-http";
export function resolveHostHeaderConfig(input) {
  return input;
}
export const hostHeaderMiddleware = options => next => async args => {
  if (!HttpRequest.isInstance(args.request)) return next(args);
  const {
    request
  } = args;
  const {
    handlerProtocol = ""
  } = options.requestHandler.metadata || {};
  if (handlerProtocol.indexOf("h2") >= 0 && !request.headers[":authority"]) {
    delete request.headers["host"];
    request.headers[":authority"] = request.hostname + (request.port ? ":" + request.port : "");
  } else if (!request.headers["host"]) {
    let host = request.hostname;
    if (request.port != null) host += `:${request.port}`;
    request.headers["host"] = host;
  }
  return next(args);
};
export const hostHeaderMiddlewareOptions = {
  name: "hostHeaderMiddleware",
  step: "build",
  priority: "low",
  tags: ["HOST"],
  override: true
};
export const getHostHeaderPlugin = options => ({
  applyToStack: clientStack => {
    clientStack.add(hostHeaderMiddleware(options), hostHeaderMiddlewareOptions);
  }
});