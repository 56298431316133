import { createAggregatedClient } from "@smithy/smithy-client";
import { AbortMultipartUploadCommand } from "./commands/AbortMultipartUploadCommand";
import { CompleteMultipartUploadCommand } from "./commands/CompleteMultipartUploadCommand";
import { CopyObjectCommand } from "./commands/CopyObjectCommand";
import { CreateBucketCommand } from "./commands/CreateBucketCommand";
import { CreateMultipartUploadCommand } from "./commands/CreateMultipartUploadCommand";
import { CreateSessionCommand } from "./commands/CreateSessionCommand";
import { DeleteBucketAnalyticsConfigurationCommand } from "./commands/DeleteBucketAnalyticsConfigurationCommand";
import { DeleteBucketCommand } from "./commands/DeleteBucketCommand";
import { DeleteBucketCorsCommand } from "./commands/DeleteBucketCorsCommand";
import { DeleteBucketEncryptionCommand } from "./commands/DeleteBucketEncryptionCommand";
import { DeleteBucketIntelligentTieringConfigurationCommand } from "./commands/DeleteBucketIntelligentTieringConfigurationCommand";
import { DeleteBucketInventoryConfigurationCommand } from "./commands/DeleteBucketInventoryConfigurationCommand";
import { DeleteBucketLifecycleCommand } from "./commands/DeleteBucketLifecycleCommand";
import { DeleteBucketMetricsConfigurationCommand } from "./commands/DeleteBucketMetricsConfigurationCommand";
import { DeleteBucketOwnershipControlsCommand } from "./commands/DeleteBucketOwnershipControlsCommand";
import { DeleteBucketPolicyCommand } from "./commands/DeleteBucketPolicyCommand";
import { DeleteBucketReplicationCommand } from "./commands/DeleteBucketReplicationCommand";
import { DeleteBucketTaggingCommand } from "./commands/DeleteBucketTaggingCommand";
import { DeleteBucketWebsiteCommand } from "./commands/DeleteBucketWebsiteCommand";
import { DeleteObjectCommand } from "./commands/DeleteObjectCommand";
import { DeleteObjectsCommand } from "./commands/DeleteObjectsCommand";
import { DeleteObjectTaggingCommand } from "./commands/DeleteObjectTaggingCommand";
import { DeletePublicAccessBlockCommand } from "./commands/DeletePublicAccessBlockCommand";
import { GetBucketAccelerateConfigurationCommand } from "./commands/GetBucketAccelerateConfigurationCommand";
import { GetBucketAclCommand } from "./commands/GetBucketAclCommand";
import { GetBucketAnalyticsConfigurationCommand } from "./commands/GetBucketAnalyticsConfigurationCommand";
import { GetBucketCorsCommand } from "./commands/GetBucketCorsCommand";
import { GetBucketEncryptionCommand } from "./commands/GetBucketEncryptionCommand";
import { GetBucketIntelligentTieringConfigurationCommand } from "./commands/GetBucketIntelligentTieringConfigurationCommand";
import { GetBucketInventoryConfigurationCommand } from "./commands/GetBucketInventoryConfigurationCommand";
import { GetBucketLifecycleConfigurationCommand } from "./commands/GetBucketLifecycleConfigurationCommand";
import { GetBucketLocationCommand } from "./commands/GetBucketLocationCommand";
import { GetBucketLoggingCommand } from "./commands/GetBucketLoggingCommand";
import { GetBucketMetricsConfigurationCommand } from "./commands/GetBucketMetricsConfigurationCommand";
import { GetBucketNotificationConfigurationCommand } from "./commands/GetBucketNotificationConfigurationCommand";
import { GetBucketOwnershipControlsCommand } from "./commands/GetBucketOwnershipControlsCommand";
import { GetBucketPolicyCommand } from "./commands/GetBucketPolicyCommand";
import { GetBucketPolicyStatusCommand } from "./commands/GetBucketPolicyStatusCommand";
import { GetBucketReplicationCommand } from "./commands/GetBucketReplicationCommand";
import { GetBucketRequestPaymentCommand } from "./commands/GetBucketRequestPaymentCommand";
import { GetBucketTaggingCommand } from "./commands/GetBucketTaggingCommand";
import { GetBucketVersioningCommand } from "./commands/GetBucketVersioningCommand";
import { GetBucketWebsiteCommand } from "./commands/GetBucketWebsiteCommand";
import { GetObjectAclCommand } from "./commands/GetObjectAclCommand";
import { GetObjectAttributesCommand } from "./commands/GetObjectAttributesCommand";
import { GetObjectCommand } from "./commands/GetObjectCommand";
import { GetObjectLegalHoldCommand } from "./commands/GetObjectLegalHoldCommand";
import { GetObjectLockConfigurationCommand } from "./commands/GetObjectLockConfigurationCommand";
import { GetObjectRetentionCommand } from "./commands/GetObjectRetentionCommand";
import { GetObjectTaggingCommand } from "./commands/GetObjectTaggingCommand";
import { GetObjectTorrentCommand } from "./commands/GetObjectTorrentCommand";
import { GetPublicAccessBlockCommand } from "./commands/GetPublicAccessBlockCommand";
import { HeadBucketCommand } from "./commands/HeadBucketCommand";
import { HeadObjectCommand } from "./commands/HeadObjectCommand";
import { ListBucketAnalyticsConfigurationsCommand } from "./commands/ListBucketAnalyticsConfigurationsCommand";
import { ListBucketIntelligentTieringConfigurationsCommand } from "./commands/ListBucketIntelligentTieringConfigurationsCommand";
import { ListBucketInventoryConfigurationsCommand } from "./commands/ListBucketInventoryConfigurationsCommand";
import { ListBucketMetricsConfigurationsCommand } from "./commands/ListBucketMetricsConfigurationsCommand";
import { ListBucketsCommand } from "./commands/ListBucketsCommand";
import { ListDirectoryBucketsCommand } from "./commands/ListDirectoryBucketsCommand";
import { ListMultipartUploadsCommand } from "./commands/ListMultipartUploadsCommand";
import { ListObjectsCommand } from "./commands/ListObjectsCommand";
import { ListObjectsV2Command } from "./commands/ListObjectsV2Command";
import { ListObjectVersionsCommand } from "./commands/ListObjectVersionsCommand";
import { ListPartsCommand } from "./commands/ListPartsCommand";
import { PutBucketAccelerateConfigurationCommand } from "./commands/PutBucketAccelerateConfigurationCommand";
import { PutBucketAclCommand } from "./commands/PutBucketAclCommand";
import { PutBucketAnalyticsConfigurationCommand } from "./commands/PutBucketAnalyticsConfigurationCommand";
import { PutBucketCorsCommand } from "./commands/PutBucketCorsCommand";
import { PutBucketEncryptionCommand } from "./commands/PutBucketEncryptionCommand";
import { PutBucketIntelligentTieringConfigurationCommand } from "./commands/PutBucketIntelligentTieringConfigurationCommand";
import { PutBucketInventoryConfigurationCommand } from "./commands/PutBucketInventoryConfigurationCommand";
import { PutBucketLifecycleConfigurationCommand } from "./commands/PutBucketLifecycleConfigurationCommand";
import { PutBucketLoggingCommand } from "./commands/PutBucketLoggingCommand";
import { PutBucketMetricsConfigurationCommand } from "./commands/PutBucketMetricsConfigurationCommand";
import { PutBucketNotificationConfigurationCommand } from "./commands/PutBucketNotificationConfigurationCommand";
import { PutBucketOwnershipControlsCommand } from "./commands/PutBucketOwnershipControlsCommand";
import { PutBucketPolicyCommand } from "./commands/PutBucketPolicyCommand";
import { PutBucketReplicationCommand } from "./commands/PutBucketReplicationCommand";
import { PutBucketRequestPaymentCommand } from "./commands/PutBucketRequestPaymentCommand";
import { PutBucketTaggingCommand } from "./commands/PutBucketTaggingCommand";
import { PutBucketVersioningCommand } from "./commands/PutBucketVersioningCommand";
import { PutBucketWebsiteCommand } from "./commands/PutBucketWebsiteCommand";
import { PutObjectAclCommand } from "./commands/PutObjectAclCommand";
import { PutObjectCommand } from "./commands/PutObjectCommand";
import { PutObjectLegalHoldCommand } from "./commands/PutObjectLegalHoldCommand";
import { PutObjectLockConfigurationCommand } from "./commands/PutObjectLockConfigurationCommand";
import { PutObjectRetentionCommand } from "./commands/PutObjectRetentionCommand";
import { PutObjectTaggingCommand } from "./commands/PutObjectTaggingCommand";
import { PutPublicAccessBlockCommand } from "./commands/PutPublicAccessBlockCommand";
import { RestoreObjectCommand } from "./commands/RestoreObjectCommand";
import { SelectObjectContentCommand } from "./commands/SelectObjectContentCommand";
import { UploadPartCommand } from "./commands/UploadPartCommand";
import { UploadPartCopyCommand } from "./commands/UploadPartCopyCommand";
import { WriteGetObjectResponseCommand } from "./commands/WriteGetObjectResponseCommand";
import { S3Client } from "./S3Client";
const commands = {
  AbortMultipartUploadCommand,
  CompleteMultipartUploadCommand,
  CopyObjectCommand,
  CreateBucketCommand,
  CreateMultipartUploadCommand,
  CreateSessionCommand,
  DeleteBucketCommand,
  DeleteBucketAnalyticsConfigurationCommand,
  DeleteBucketCorsCommand,
  DeleteBucketEncryptionCommand,
  DeleteBucketIntelligentTieringConfigurationCommand,
  DeleteBucketInventoryConfigurationCommand,
  DeleteBucketLifecycleCommand,
  DeleteBucketMetricsConfigurationCommand,
  DeleteBucketOwnershipControlsCommand,
  DeleteBucketPolicyCommand,
  DeleteBucketReplicationCommand,
  DeleteBucketTaggingCommand,
  DeleteBucketWebsiteCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
  DeleteObjectTaggingCommand,
  DeletePublicAccessBlockCommand,
  GetBucketAccelerateConfigurationCommand,
  GetBucketAclCommand,
  GetBucketAnalyticsConfigurationCommand,
  GetBucketCorsCommand,
  GetBucketEncryptionCommand,
  GetBucketIntelligentTieringConfigurationCommand,
  GetBucketInventoryConfigurationCommand,
  GetBucketLifecycleConfigurationCommand,
  GetBucketLocationCommand,
  GetBucketLoggingCommand,
  GetBucketMetricsConfigurationCommand,
  GetBucketNotificationConfigurationCommand,
  GetBucketOwnershipControlsCommand,
  GetBucketPolicyCommand,
  GetBucketPolicyStatusCommand,
  GetBucketReplicationCommand,
  GetBucketRequestPaymentCommand,
  GetBucketTaggingCommand,
  GetBucketVersioningCommand,
  GetBucketWebsiteCommand,
  GetObjectCommand,
  GetObjectAclCommand,
  GetObjectAttributesCommand,
  GetObjectLegalHoldCommand,
  GetObjectLockConfigurationCommand,
  GetObjectRetentionCommand,
  GetObjectTaggingCommand,
  GetObjectTorrentCommand,
  GetPublicAccessBlockCommand,
  HeadBucketCommand,
  HeadObjectCommand,
  ListBucketAnalyticsConfigurationsCommand,
  ListBucketIntelligentTieringConfigurationsCommand,
  ListBucketInventoryConfigurationsCommand,
  ListBucketMetricsConfigurationsCommand,
  ListBucketsCommand,
  ListDirectoryBucketsCommand,
  ListMultipartUploadsCommand,
  ListObjectsCommand,
  ListObjectsV2Command,
  ListObjectVersionsCommand,
  ListPartsCommand,
  PutBucketAccelerateConfigurationCommand,
  PutBucketAclCommand,
  PutBucketAnalyticsConfigurationCommand,
  PutBucketCorsCommand,
  PutBucketEncryptionCommand,
  PutBucketIntelligentTieringConfigurationCommand,
  PutBucketInventoryConfigurationCommand,
  PutBucketLifecycleConfigurationCommand,
  PutBucketLoggingCommand,
  PutBucketMetricsConfigurationCommand,
  PutBucketNotificationConfigurationCommand,
  PutBucketOwnershipControlsCommand,
  PutBucketPolicyCommand,
  PutBucketReplicationCommand,
  PutBucketRequestPaymentCommand,
  PutBucketTaggingCommand,
  PutBucketVersioningCommand,
  PutBucketWebsiteCommand,
  PutObjectCommand,
  PutObjectAclCommand,
  PutObjectLegalHoldCommand,
  PutObjectLockConfigurationCommand,
  PutObjectRetentionCommand,
  PutObjectTaggingCommand,
  PutPublicAccessBlockCommand,
  RestoreObjectCommand,
  SelectObjectContentCommand,
  UploadPartCommand,
  UploadPartCopyCommand,
  WriteGetObjectResponseCommand
};
export class S3 extends S3Client {}
createAggregatedClient(commands, S3);