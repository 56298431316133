export const parseArn = value => {
  const segments = value.split(":");
  if (segments.length < 6) return null;
  const [arn, partition, service, region, accountId, ...resourceId] = segments;
  if (arn !== "arn" || partition === "" || service === "" || resourceId[0] === "") return null;
  return {
    partition,
    service,
    region,
    accountId,
    resourceId: resourceId[0].includes("/") ? resourceId[0].split("/") : resourceId
  };
};