import { HttpResponse } from "@smithy/protocol-http";
import { parseRfc7231DateTime } from "@smithy/smithy-client";
export const s3ExpiresMiddleware = config => {
  return (next, context) => async args => {
    const result = await next(args);
    const {
      response
    } = result;
    if (HttpResponse.isInstance(response)) {
      if (response.headers.expires) {
        response.headers.expiresstring = response.headers.expires;
        try {
          parseRfc7231DateTime(response.headers.expires);
        } catch (e) {
          context.logger?.warn(`AWS SDK Warning for ${context.clientName}::${context.commandName} response parsing (${response.headers.expires}): ${e}`);
          delete response.headers.expires;
        }
      }
    }
    return result;
  };
};
export const s3ExpiresMiddlewareOptions = {
  tags: ["S3"],
  name: "s3ExpiresMiddleware",
  override: true,
  relation: "after",
  toMiddleware: "deserializerMiddleware"
};
export const getS3ExpiresMiddlewarePlugin = clientConfig => ({
  applyToStack: clientStack => {
    clientStack.addRelativeTo(s3ExpiresMiddleware(clientConfig), s3ExpiresMiddlewareOptions);
  }
});