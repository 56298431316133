"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EMPTY_DATA_SHA_1 = exports.SHA_1_HMAC_ALGO = exports.SHA_1_HASH = void 0;
exports.SHA_1_HASH = {
  name: "SHA-1"
};
exports.SHA_1_HMAC_ALGO = {
  name: "HMAC",
  hash: exports.SHA_1_HASH
};
exports.EMPTY_DATA_SHA_1 = new Uint8Array([218, 57, 163, 238, 94, 107, 75, 13, 50, 85, 191, 239, 149, 96, 24, 144, 175, 216, 7, 9]);
