export const regionRedirectEndpointMiddleware = config => {
  return (next, context) => async args => {
    const originalRegion = await config.region();
    const regionProviderRef = config.region;
    if (context.__s3RegionRedirect) {
      config.region = async () => {
        config.region = regionProviderRef;
        return context.__s3RegionRedirect;
      };
    }
    const result = await next(args);
    if (context.__s3RegionRedirect) {
      const region = await config.region();
      if (originalRegion !== region) {
        throw new Error("Region was not restored following S3 region redirect.");
      }
    }
    return result;
  };
};
export const regionRedirectEndpointMiddlewareOptions = {
  tags: ["REGION_REDIRECT", "S3"],
  name: "regionRedirectEndpointMiddleware",
  override: true,
  relation: "before",
  toMiddleware: "endpointV2Middleware"
};