"use strict";

// Copyright Amazon.com Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToBuffer = void 0;
var util_utf8_browser_1 = require("@aws-sdk/util-utf8-browser");
// Quick polyfill
var fromUtf8 = typeof Buffer !== "undefined" && Buffer.from ? function (input) {
  return Buffer.from(input, "utf8");
} : util_utf8_browser_1.fromUtf8;
function convertToBuffer(data) {
  // Already a Uint8, do nothing
  if (data instanceof Uint8Array) return data;
  if (typeof data === "string") {
    return fromUtf8(data);
  }
  if (ArrayBuffer.isView(data)) {
    return new Uint8Array(data.buffer, data.byteOffset, data.byteLength / Uint8Array.BYTES_PER_ELEMENT);
  }
  return new Uint8Array(data);
}
exports.convertToBuffer = convertToBuffer;
