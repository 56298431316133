export const serializerMiddleware = (options, serializer) => (next, context) => async args => {
  const endpoint = context.endpointV2?.url && options.urlParser ? async () => options.urlParser(context.endpointV2.url) : options.endpoint;
  if (!endpoint) {
    throw new Error("No valid endpoint provider available.");
  }
  const request = await serializer(args.input, {
    ...options,
    endpoint
  });
  return next({
    ...args,
    request
  });
};