import { serializerMiddlewareOption } from "@smithy/middleware-serde";
import { endpointMiddleware } from "./endpointMiddleware";
export const endpointMiddlewareOptions = {
  step: "serialize",
  tags: ["ENDPOINT_PARAMETERS", "ENDPOINT_V2", "ENDPOINT"],
  name: "endpointV2Middleware",
  override: true,
  relation: "before",
  toMiddleware: serializerMiddlewareOption.name
};
export const getEndpointPlugin = (config, instructions) => ({
  applyToStack: clientStack => {
    clientStack.addRelativeTo(endpointMiddleware({
      config,
      instructions
    }), endpointMiddlewareOptions);
  }
});