export class Fields {
  constructor({
    fields = [],
    encoding = "utf-8"
  }) {
    this.entries = {};
    fields.forEach(this.setField.bind(this));
    this.encoding = encoding;
  }
  setField(field) {
    this.entries[field.name.toLowerCase()] = field;
  }
  getField(name) {
    return this.entries[name.toLowerCase()];
  }
  removeField(name) {
    delete this.entries[name.toLowerCase()];
  }
  getByType(kind) {
    return Object.values(this.entries).filter(field => field.kind === kind);
  }
}