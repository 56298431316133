export const getHttpHandlerExtensionConfiguration = runtimeConfig => {
  let httpHandler = runtimeConfig.httpHandler;
  return {
    setHttpHandler(handler) {
      httpHandler = handler;
    },
    httpHandler() {
      return httpHandler;
    },
    updateHttpClientConfig(key, value) {
      httpHandler.updateHttpClientConfig(key, value);
    },
    httpHandlerConfigs() {
      return httpHandler.httpHandlerConfigs();
    }
  };
};
export const resolveHttpHandlerRuntimeConfig = httpHandlerExtensionConfiguration => {
  return {
    httpHandler: httpHandlerExtensionConfiguration.httpHandler()
  };
};